<template>
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <!-- 选择状态 -->
        <el-select v-model="examineStatusVal" placeholder="审核状态" clearable @change="onCurrentPage">
          <el-option v-for="item in examineStatus" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 应标资格 -->
        <el-select v-model="bidEligibilityVal" placeholder="应标资格" clearable @change="onCurrentPage">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="levelName" label="应标级别名称" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="eligibleType" label="资格类型" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div>
                {{ getEligibleTypeText(scope.row.eligibleType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="eligiblePrice" label="金额" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="myTendersCount" label="可投标商品次数（次）" show-overflow-tooltip min-width="160"
            align="center">
          </el-table-column>
          <el-table-column prop="validDate" label="资格有效期（天）" show-overflow-tooltip min-width="140" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.validDate ? scope.row.validDate : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="有效期区间" show-overflow-tooltip min-width="200" align="center">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center;">
                <div style="flex: 1;">{{ scope.row.startTime }}</div>
                <span style="margin: 0 5px;color: gray;">-</span>
                <div style="flex: 1;">{{ scope.row.endTime }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="缴费状态" min-width="100" align="center">
            <template slot-scope="scope">
              <div :style="{ color: getStatusColor(scope.row.payStatus) }">
                {{ getPayStatusText(scope.row.payStatus) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" min-width="100" align="center">
            <template slot-scope="scope">
              <div :style="{ color: getStatusColor(scope.row.status) }">
                {{ getStatusText(scope.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" fixed="right" min-width="160" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.payStatus == 0">
                <el-button type="primary" size="small" @click="handlePay(scope.row)">去缴费</el-button>
                <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">查看</el-button>
              </div>
              <div v-else>
                <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">查看</el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
    <!-- 手机验证码 -->
    <child :showFlag="showFlag" @closeChildDialog="closeChildDialog" v-if="flag"></child>
  </div>
</template>

<script>
import child from './detail/paymentDet.vue'
export default {
  components: {
    child,
  },
  data() {
    return {
      loading: false,
      showFlag: false,
      flag: false,

      // 招标信息数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 审核状态（0：待审核，1.审核通过，2.审核不通过） 
      examineStatus: [
        {
          value: '0',
          label: '待审核'
        }, {
          value: '1',
          label: '审核通过'
        }, {
          value: '2',
          label: '审核不通过'
        },
      ],
      //应标资格
      selectData: [{
        value: '1',
        label: '平台资格'
      }, {
        value: '2',
        label: '独立标资格'
      }],
      examineStatusVal: '',//审核状态
      bidEligibilityVal: '',//应标资格
      searchNameTxt: '',//标书名称
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productList", {})
    // 获取列表
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendBiddingEligible, {
        params: {
          auditStatus: this.examineStatusVal || null,//审核状态
          eligibleType: this.bidEligibilityVal || null,//应标资格类型
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list.map(e => {
            return {
              ...e,
              myTendersCount: e.tendersCount == -1 ? "不限" : e.tendersCount
            }
          })
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      })

    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getRequest()
    },
    // 跳转
    handleCommand(row) {
      let obj = {
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productList", obj)
      this.$router.push({ name: 'detailResBidManDet', query: { biddingId: row.id } })
    },
    // 检查用户当前支付平台绑定状态
    handlePay(row) {
      this.flag = true
      //  缓存第三方支付的获取验证码参数和支付参数
      let query = {
        sourceId: row.id, // 对应平台的主键id
        fee: row.eligiblePrice, // 缴费金额
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
        sourceType: row.eligibleType, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
      }
      this.$store.commit('setPayQuery', query)
      this.$axios.post(this.$api.select_token, {
        phoneNum: this.$store.state.userInfo.userAccount, // 手机号
        supplierId: this.$store.state.userInfo.supplierId, // 供应商id
      }).then((res) => {
        if (res.data.code == 100) {
          // result为空时该供应商没有绑定第三方支付平台
          if (res.data.result == null) {
            this.showFlag = true
          } else {
            this.$router.push({ name: 'paymentPage' })
          }
        }
      });
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog() {
      this.showFlag = false
    },
    // 状态文字颜色
    getStatusColor(status) {
      switch (status) {
        case '0':
          return '#069BBC';//待审核
        case '2':
          return '#E35B5A';//审核不通过
        default:
          return '';//审核不通过/-
      }
    },
    // 状态文字显示
    getStatusText(status) {
      switch (status) {
        case '0':
          return '待审核';
        case '1':
          return '审核通过';
        case '2':
          return '审核不通过';
        default:
          return '-';
      }
    },
    getPayStatusText(status) {
      switch (status) {
        case '0':
          return '未支付';
        default:
          return '已支付';
      }
    },
    getEligibleTypeText(status) {
      switch (status) {
        case '1':
          return '平台资格';
        default:
          return '独立标资格';
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    // 头部+搜索
    // header {
    //   display: flex;
    //   justify-content: flex-end;

    //   // .right {
    //   //   display: flex;
    //   margin-bottom: 20px;

    //   .select {
    //     display: flex;
    //     justify-content: space-between;

    //     .el-select {
    //       margin-left: 10px;
    //       width: 200px;
    //     }

    //   }

    //   // }
    // }
    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }


    }
  }

  .tableBox {
    overflow: auto;


    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
